var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Display',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Turn a group of "),_c('code',[_vm._v("<b-collapse>")]),_vm._v(" components into an "),_c('code',[_vm._v("accordion")]),_vm._v(" by supplying an accordion group identifier via the accordion prop. Note that only one collapse in an accordion group can be open at a time. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }